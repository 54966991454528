import { Props as BenefitsProps } from 'app/components/Benefits'
import { Props as GalleryProps } from 'app/components/Gallery'
import { Props as HeroProps } from 'app/components/Hero'
import { Props as IntroProps } from 'app/components/Intro'
import { Props as RoomsSliderProps } from 'app/components/RoomsSlider'
import { Props as SEOProps } from 'app/components/SEO'
import { Props as ServicesStripeProps } from 'app/components/ServicesStripe'
import { CreatePagesQuery } from 'graphql-types'
import { compact } from 'lodash'

import { PageContext } from '.'

export interface Props {
  seoProps: SEOProps | undefined
  heroProps: HeroProps | undefined
  introProps: IntroProps | undefined
  servicesStripeProps: ServicesStripeProps | undefined
  galleryProps: GalleryProps | undefined
  roomsSliderProps: RoomsSliderProps | undefined
  benefitsProps: BenefitsProps | undefined
}

export const getRoomPageProps = (
  query: CreatePagesQuery,
  pageContext: PageContext,
): Props => {
  return {
    seoProps: getSEOProps(query, pageContext),
    heroProps: getHeroProps(query, pageContext),
    introProps: getIntroProps(query, pageContext),
    servicesStripeProps: getServicesStripeProps(query, pageContext),
    galleryProps: getGalleryProps(query, pageContext),
    roomsSliderProps: getRoomsSliderProps(query, pageContext),
    benefitsProps: getBenefitsProps(query, pageContext),
  }
}

const getSEOProps = (
  query: CreatePagesQuery,
  pageContext: PageContext,
): SEOProps | undefined => {
  const roomNodes = compact(query.cms.room).find(
    ({ id }) => id === pageContext.id,
  )

  if (!roomNodes) {
    return undefined
  }

  const translation = roomNodes.translations?.find(
    (t) => t?.languages_code?.code === pageContext.languageCode,
  )

  if (!translation) {
    return undefined
  }

  const title = translation.metatag_title || undefined
  const description = translation.metatag_description || undefined
  const keywords = translation?.metatag_keywords || undefined
  const slug = translation.slug

  return {
    title,
    description,
    keywords,
    languagePrefix: pageContext.languagePrefix || 'it',
    url: `${
      pageContext.languagePrefix ? `/${pageContext.languagePrefix}` : ''
    }/${slug}`,
  }
}

const getHeroProps = (
  query: CreatePagesQuery,
  pageContext: PageContext,
): HeroProps | undefined => {
  const roomNodes = compact(query.cms.room || []).find(
    ({ id }) => id === pageContext.id,
  )

  if (!roomNodes) {
    return undefined
  }

  const translation = roomNodes.translations?.find(
    (t) => t?.languages_code?.code === pageContext.languageCode,
  )

  if (!translation) {
    return undefined
  }

  const claim = translation.title || undefined
  const pax = translation.pax || undefined
  const size = translation.size || undefined
  const heroImage = roomNodes.hero_image?.file?.childImageSharp
  const alt = roomNodes.hero_image?.title || undefined
  const src = heroImage?.gatsbyImageData.images.fallback.src
  const srcSet = heroImage?.gatsbyImageData.images.fallback.srcSet
  const width = heroImage?.original?.width || undefined
  const height = heroImage?.original?.height || undefined

  const slides = heroImage
    ? [
        {
          alt,
          src,
          srcSet,
          width,
          height,
        },
      ]
    : undefined

  return {
    claim,
    pax,
    size,
    slides,
  }
}

const getIntroProps = (
  query: CreatePagesQuery,
  pageContext: PageContext,
): IntroProps | undefined => {
  const roomNodes = compact(query.cms.room || []).find(
    ({ id }) => id === pageContext.id,
  )

  if (!roomNodes) {
    return undefined
  }

  const translation = roomNodes.translations?.find(
    (t) => t?.languages_code?.code === pageContext.languageCode,
  )

  if (!translation) {
    return undefined
  }

  const description = translation?.intro_description || undefined
  const title = translation?.intro_title || undefined

  return {
    description,
    title,
  }
}

const getServicesStripeProps = (
  query: CreatePagesQuery,
  pageContext: PageContext,
): ServicesStripeProps | undefined => {
  const roomNodes = compact(query.cms.room || []).find(
    ({ id }) => id === pageContext.id,
  )

  if (!roomNodes) {
    return undefined
  }

  const translation = roomNodes.translations?.find(
    (t) => t?.languages_code?.code === pageContext.languageCode,
  )

  if (!translation) {
    return undefined
  }

  const comfortTitle = translation?.icons_services_title || undefined
  const comfortServices = compact(translation?.icons_services_comfort).map(
    ({ icons_services_id }) => {
      const translation = icons_services_id?.translations?.find(
        (t) => t?.languages_code?.code === pageContext.languageCode,
      )
      const iconSource = icons_services_id?.icon?.file?.publicURL || undefined
      const name = translation?.label || undefined

      return {
        icon: iconSource,
        name,
      }
    },
  )

  const plusTitle = translation?.icons_services_title_2 || undefined
  const plusServices = compact(translation?.icons_services_plus).map(
    ({ icons_services_id }) => {
      const translation = icons_services_id?.translations?.find(
        (t) => t?.languages_code?.code === pageContext.languageCode,
      )
      const iconSource = icons_services_id?.icon?.file?.publicURL || undefined
      const name = translation?.label || undefined

      return {
        icon: iconSource,
        name,
      }
    },
  )

  return {
    comfortServices,
    comfortTitle,
    plusTitle,
    plusServices,
  }
}

const getGalleryProps = (
  query: CreatePagesQuery,
  pageContext: PageContext,
): GalleryProps | undefined => {
  const roomNodes = compact(query.cms.room || []).find(
    ({ id }) => id === pageContext.id,
  )

  if (!roomNodes) {
    return undefined
  }

  const translation = roomNodes.translations?.find(
    (t) => t?.languages_code?.code === pageContext.languageCode,
  )

  if (!translation) {
    return undefined
  }

  const images = compact(roomNodes.gallery_images).map(
    ({ directus_files_id }) => {
      const image = directus_files_id?.file?.childImageSharp

      return {
        languageCode: pageContext.languageCode,
        original: image?.gatsbyImageData.images.fallback.src,
        thumbnail: image?.thumbnail.images.fallback.src,
        title: directus_files_id?.title || undefined,
        width: image?.gatsbyImageData.width,
        height: image?.gatsbyImageData.height,
      }
    },
  )

  return {
    images,
  }
}

const getRoomsSliderProps = (
  query: CreatePagesQuery,
  pageContext: PageContext,
): RoomsSliderProps | undefined => {
  const roomNodes = compact(query.cms.room || []).find(
    ({ id }) => id === pageContext.id,
  )

  if (!roomNodes) {
    return undefined
  }

  const translation = roomNodes.translations?.find(
    (t) => t?.languages_code?.code === pageContext.languageCode,
  )

  if (!translation) {
    return undefined
  }

  const title = translation?.other_rooms_title || undefined

  const rooms = compact(query.cms.room)
    .filter(({ id }) => id !== pageContext.id)
    .map(({ status, hero_image, translations }) => {
      if (status !== 'published') {
        return null
      }

      const image =
        hero_image?.file?.childImageSharp?.thumbnail.images.fallback.src ||
        undefined

      const translation = translations?.find(
        (t: any) => t?.languages_code?.code === pageContext.languageCode,
      )

      const pax = translation?.pax || undefined
      const size = translation?.size || undefined
      const title = translation?.title
      const parentSlug = translation?.parent_slug
        ? `/${translation?.parent_slug}`
        : ''
      const slug = translation?.slug
      const URL = pageContext.languagePrefix
        ? `/${pageContext.languagePrefix}${parentSlug}/${slug}`
        : `${parentSlug}/${slug}`

      return title
        ? {
            image,
            languageCode: pageContext.languageCode,
            pax,
            size,
            title,
            URL,
          }
        : undefined
    })

  return {
    rooms: compact(rooms),
    title,
  }
}

const getBenefitsProps = (
  query: CreatePagesQuery,
  pageContext: PageContext,
): BenefitsProps | undefined => {
  const roomNodes = compact(query.cms.room || []).find(
    ({ id }) => id === pageContext.id,
  )

  if (!roomNodes) {
    return undefined
  }

  const translation = roomNodes.translations?.find(
    (t) => t?.languages_code?.code === pageContext.languageCode,
  )

  if (!translation) {
    return undefined
  }

  const benefits = compact(translation?.benefits_list).map(
    (item: any) => item.label,
  )
  const IBEID = query.cms.company?.ibeid || undefined
  const imageObj = roomNodes.benefits_image?.file?.childImageSharp
  const image = imageObj
    ? {
        alt: roomNodes.benefits_image?.title,
        src: imageObj?.gatsbyImageData.images.fallback.src,
        srcSet: imageObj?.gatsbyImageData.images.fallback.srcSet,
        width: imageObj?.original?.width,
        height: imageObj?.original?.height,
      }
    : undefined
  const title = translation?.benefits_title || undefined

  return {
    benefits,
    IBEID,
    image,
    languageCode: pageContext.languageCode,
    languagePrefix: pageContext.languagePrefix || 'it',
    title,
  }
}
