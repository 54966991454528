import styled from '@emotion/styled'
import { Benefits } from 'app/components/Benefits'
import { Gallery } from 'app/components/Gallery'
import { Hero } from 'app/components/Hero'
import { Intro } from 'app/components/Intro'
import { RoomsSlider } from 'app/components/RoomsSlider'
import { SEO } from 'app/components/SEO'
import { ServicesStripe } from 'app/components/ServicesStripe'
import { Footer } from 'app/containers/Footer'
import { GoogleAnalytics } from 'app/containers/GoogleAnalytics'
import { Hashtag } from 'app/containers/Hashtag'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { StructuredData } from 'app/containers/StructuredData'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export { getRoomPageProps } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function RoomPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      <GoogleAnalytics />
      {pageContext.headerProps ? (
        <Header
          pageID={pageContext.id}
          languageCode={pageContext.languageCode}
          languagePrefix={pageContext.languagePrefix}
          {...pageContext.headerProps}
        />
      ) : null}
      {context.heroProps ? <Hero {...context.heroProps} /> : null}
      {context.introProps ? (
        <Intro variant="compact" {...context.introProps} />
      ) : null}
      {context.servicesStripeProps ? (
        <ServicesStripe {...context.servicesStripeProps} />
      ) : null}
      {context.galleryProps ? <Gallery {...context.galleryProps} /> : null}
      {context.roomsSliderProps ? (
        <RoomsSlider variant="compact" {...context.roomsSliderProps} />
      ) : null}
      {context.benefitsProps ? <Benefits {...context.benefitsProps} /> : null}
      <Hashtag />
      <Footer
        languageCode={pageContext.languageCode}
        languagePrefix={pageContext.languagePrefix || 'it'}
      />
      <StructuredData languageCode={pageContext.languageCode} />
    </Container>
  )
})

const Container = styled.main``
